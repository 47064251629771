import Container from 'components/container'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
    > div {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

interface IMainLayout {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    disableFooter?: boolean
    maxWidth?: 'sm' | 'lg' | 'md' | 'xs'
}

const MainLayout = ({ children, maxWidth = 'md' }: IMainLayout) => {
    return (
        <StyledContainer maxWidth={maxWidth}>
            <div>{children}</div>
        </StyledContainer>
    )
}

export default MainLayout
