import * as React from 'react'
import MainLayout from 'components/main-layout'
import Input from 'components/input'
import Button from 'components/button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useConfigParams, useWindowSize } from 'hooks'
import ErrorComponent from 'components/error-component'
import CardLayout from 'components/card-layout'
import Logo from 'assets/svgs/logo.svg'
import QrCode from './components/qr-code'
import { CircularProgress, InputAdornment } from '@mui/material'
import SuccessMessage from './components/success-message'
import { useTheme } from '@emotion/react'
import { addPhonePrefix } from 'utils/phone'
import { Wrapper, LeftWrapper, TextCta, RightWrapper, Head, Error } from './components/styled-components'
import { getAuth, ConfirmationResult } from 'firebase/auth'
import ConfirmStep from './components/confirm-step'
import useAuth from 'hooks/use-auth'
import useStoreListener from 'hooks/use-store-listener'
import { AuthActions } from 'store/auth/types'
const auth = getAuth()

const grantValidationSchema = yup.object().shape({
    user: yup
        .string()
        .required('Il campo è richiesto')
        .matches(
            /(^\w((\w|[-#&%*+=!|+*_]){1,})*(\.(\w|[-#&%*+=!+|*_]){1,})*@\w{2,}(\.\w{2,4})+$)|(^(\+[1-9])?\d{4,14}$)/,
            'Non sembra essere una mail o un telefono',
        ),
})

const loginValidationSchema = yup.object().shape({
    code: yup.string().required('Il campo è richiesto'),
})

const LoginPage = () => {
    const { isLoading, restoreLoading, handleLogin, isLogged, handleRequestGrant, phoneConfirm } = useAuth()
    const { redirectUri, clientId, scope, state } = useConfigParams()
    const [error, setError] = React.useState('')
    const [step, setStep] = React.useState<number>(0)
    /* const [phoneConfirm, setPhoneConfirm] = React.useState<ConfirmationResult | undefined>() */
    const theme = useTheme()
    const { width } = useWindowSize()

    const isMobile = React.useMemo(() => {
        return width <= (theme as any).breakpoints.values.sm
    }, [width, (theme as any).breakpoints])

    /* const handleResendPhoneCode = async (): Promise<void> => {
        const appVerifier = (window as any).recaptchaVerifier
        const confirm = await signInWithPhoneNumber(auth, addPhonePrefix(form.values.user), appVerifier)
        setPhoneConfirm(confirm)
    } */

    useStoreListener({
        [AuthActions.REQUEST_GRANT_SUCCESS]: () => {
            setStep(1)
        },
        [AuthActions.REQUEST_GRANT_ERROR]: (payload) => {
            setError(payload.message)
        },
        [AuthActions.LOGIN_ERROR]: (payload) => {
            setError(payload.message)
        },
        [AuthActions.LOGOUT_SUCCESS]: (payload) => {
            setStep(0)
        },
    })

    const handleSubmit = async (values: { user: string; code: string }) => {
        if (scope && redirectUri && clientId) {
            if (step === 0) {
                handleRequestGrant({ type: inputType === 'mail' ? 'mail' : 'phone', user: values.user })
            }
            if (step === 1) {
                const code = values.code.replace(/-/g, '')

                if (inputType === 'mail') {
                    handleLogin({ loginType: 'email', grant: code })
                } else if (inputType === 'phone') {
                    handleLogin({ loginType: 'phone', grant: code, phoneConfirm })
                }
            }
        }
    }

    const form = useFormik({
        initialValues: {
            user: '',
            code: '',
        },
        onSubmit: handleSubmit,
        validationSchema: () => {
            switch (step) {
                case 0:
                    return grantValidationSchema
                case 1:
                    return loginValidationSchema
            }
        },
    })

    const inputType = React.useMemo<'mail' | 'phone' | 'invalid'>((): 'mail' | 'phone' | 'invalid' => {
        return form.values.user ? (form.values.user.includes('@') ? 'mail' : 'phone') : 'invalid'
    }, [form.values.user])

    const reset = React.useCallback(() => {
        form.resetForm()
        setStep(0)
    }, [])

    React.useEffect(() => {
        console.log('store loading', isLoading)
    }, [isLoading])

    React.useEffect(() => {
        if (error) {
            setError('')
        }
    }, [form.values])

    const title = React.useMemo(() => {
        switch (step) {
            case 1:
                return 'Authcode'
            default:
                return 'Accedi'
        }
    }, [step])

    if (!scope || !redirectUri || !clientId)
        return (
            <MainLayout>
                <ErrorComponent text="La rotta su cui sei atterrato non è corretta." />
            </MainLayout>
        )
    if (step === 3) {
        return (
            <MainLayout>
                <SuccessMessage />
            </MainLayout>
        )
    }
    if (restoreLoading) {
        return (
            <CardLayout maxWidth="sm">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '15rem' }}>
                    <CircularProgress color="primary" />
                </div>
            </CardLayout>
        )
    }
    if (isLogged) {
        return (
            <CardLayout maxWidth="sm">
                <ConfirmStep />
            </CardLayout>
        )
    }
    return (
        <CardLayout maxWidth={step > 0 ? 'sm' : undefined}>
            <Wrapper>
                <LeftWrapper>
                    <Head>{step === 1 ? <img src={Logo} style={{ width: '4rem' }} alt="WeStudents" /> : null}</Head>
                    <h1 style={{ textAlign: 'center' }}>{title}</h1>
                    {step === 0 ? (
                        <p style={{ textAlign: 'center' }}>
                            Digita la mail o il numero di telefono associato a WeStudents, ti invieremo un codice di
                            verifica con cui effettuare l’accesso rapido.
                        </p>
                    ) : null}
                    {step === 1 ? (
                        <p style={{ textAlign: 'center' }}>
                            {`Abbiamo inviato un codice di sicurezza a ${form.values.user}. Inseriscilo nel box di seguito
                            entro 15 minuti.`}
                        </p>
                    ) : null}
                    {step === 0 ? (
                        <Input
                            fullWidth
                            helperText={form.touched.user ? form.errors.user : ''}
                            value={form.values.user}
                            onChange={form.handleChange('user')}
                            label="Mail o numero di telefono"
                        />
                    ) : null}
                    {step === 1 ? (
                        <Input
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <TextCta
                                            onClick={() => {
                                                if (!isLoading) {
                                                    handleRequestGrant({
                                                        type: inputType === 'mail' ? 'mail' : 'phone',
                                                        user: form.values.user,
                                                        resend: true,
                                                    })
                                                }
                                            }}>
                                            Invia di nuovo
                                        </TextCta>
                                    </InputAdornment>
                                ),
                            }}
                            fieldType={inputType === 'mail' ? 'mail-code' : 'phone-code'}
                            helperText={form.touched.code ? form.errors.code : ''}
                            value={form.values.code}
                            onChange={form.handleChange('code')}
                        />
                    ) : null}
                    {error ? <Error>{error}</Error> : null}
                    <Button
                        id="sign-in-button"
                        size="large"
                        fullWidth
                        variant="contained"
                        onClick={() => form.handleSubmit()}
                        loading={isLoading || undefined}
                        style={{ marginTop: '5rem' }}>
                        {step === 0 ? 'Continua' : 'Conferma'}
                    </Button>
                    {step === 0 ? (
                        <p style={{ textAlign: 'center' }}>
                            Non hai un account WeStudents? <a>Scarica l’app!</a>
                        </p>
                    ) : null}
                    {step === 1 ? (
                        <p style={{ textAlign: 'center' }}>
                            <a onClick={() => reset()}>Prova un altro metodo</a>
                        </p>
                    ) : null}
                </LeftWrapper>
                {step < 1 && !isMobile ? (
                    <RightWrapper>
                        <QrCode />
                    </RightWrapper>
                ) : null}
            </Wrapper>
        </CardLayout>
    )
}

export default LoginPage
