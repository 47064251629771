import * as React from 'react'
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'
import styled from 'styled-components'
interface IButtonProps extends ButtonProps {
    loading?: boolean
}

const StyledButton = styled(MuiButton)`
    text-transform: none !important;
`

const Button = (props: IButtonProps) => {
    return (
        <StyledButton {...props}>
            {!props.loading ? <h4 style={{ margin: 0 }}>{props.children}</h4> : <CircularProgress color="info" />}
        </StyledButton>
    )
}

export default Button
