import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
`

export const LeftWrapper = styled.div`
    padding: 2.5rem;
    flex: 2;
    box-sizing: border-box;
`

export const Head = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const Error = styled.h5`
    color: ${({ theme }) => theme.palette.error.main};
    margin: 0.5rem 0;
`
export const RightWrapper = styled.div`
    border-left: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
    padding: 2.5rem;
    display: flex;
    flex: 1.8;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TextCta = styled.div`
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
        filter: brightness(0.8);
        cursor: pointer;
    }
`
