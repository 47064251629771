import MainLayout from 'components/main-layout'

import SuccessMessage from './components/success-message'

const SuccessPage = () => {
    return (
        <MainLayout>
            <SuccessMessage />
        </MainLayout>
    )
}

export default SuccessPage
