import Api from './api'
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    signInWithPhoneNumber,
    ApplicationVerifier,
    setPersistence,
    inMemoryPersistence,
    Auth as FirebaseAuth,
} from 'firebase/auth'

import { handleError } from './utils/ws-sdk-error'

interface WsSdkProps {
    apiUrl: string
    wsClientId: string
}

const API_URL = process.env.REACT_APP_API_URL || ''
const WESTUDENTS_CLIENT_ID = process.env.REACT_APP_WS_CLIENT_ID || ''

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

class WsSDK {
    private static instance: WsSDK
    private api: Api | undefined
    private firebaseAuth: FirebaseAuth | undefined
    private authData?: WsAuthData

    constructor({ wsClientId, apiUrl }: WsSdkProps) {
        if (!WsSDK.instance) {
            WsSDK.instance = this
        }
        initializeApp(firebaseConfig)

        WsSDK.instance.api = new Api({ wsClientId, apiUrl })
        WsSDK.instance.firebaseAuth = getAuth()
        WsSDK.instance.firebaseAuth.languageCode = 'it'
        setPersistence(WsSDK.instance.firebaseAuth, inMemoryPersistence)

        return WsSDK.instance
    }

    public static getInstance(): WsSDK {
        if (WsSDK.instance === null || !(WsSDK.instance instanceof WsSDK)) {
            throw new Error('WS SDK not initialized yet')
        }
        return WsSDK.instance
    }

    public setAuthData(authData: WsAuthData): void {
        WsSDK.instance.api?.setAuthData(authData)
        WsSDK.instance.authData = authData
    }

    public deleteAuthData(): void {
        WsSDK.instance.api?.deleteAuthData()
        WsSDK.instance.authData = undefined
    }

    public async requestGrant({ email }: RequestGrantProps): Promise<RequestGrantResponse> {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: `auth/login`,
                data: {
                    method: 'EMAIL',
                    account_level: 'student',
                    email,
                },
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_GRANT_REQUEST', error)
        }
    }

    public async getToken({ loginType, grant }: GetTokenProps): Promise<GetTokenResponse> {
        try {
            let bodyData

            if (loginType === 'email') {
                bodyData = {
                    grant_type: 'authorization_code',
                    grant,
                }
            } else {
                bodyData = {
                    grant_type: 'implicit',
                    account_level: 'student',
                    token: grant,
                }
            }

            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: 'auth/oauth/token',
                data: bodyData,
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_GET_TOKEN', error)
        }
    }

    public async requestPhoneGrant(phone: string, appVerifier: ApplicationVerifier): Promise<any> {
        try {
            if (WsSDK.instance.firebaseAuth) {
                const response = await signInWithPhoneNumber(WsSDK.instance.firebaseAuth, phone, appVerifier)
                return response
            } else {
                throw new Error('Firebase auth not initialized')
            }
        } catch (error) {
            throw handleError('ERR_GET_TOKEN', error)
        }
    }

    public async createQrCode() {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: `auth/qrcode`,
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_CREATE_QR_CODE', error)
        }
    }

    public async checkQr({ qrCode, key }: { qrCode: string; key: string }): Promise<GetTokenResponse> {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'GET',
                url: `auth/qrcode?qr_code=${qrCode}&key=${key}`,
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_CHECKING_QR_CODE', error)
        }
    }

    public async authorizeClient({ clientId, ...props }: AuthorizeClientProps): Promise<ApiResponse<string>> {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: `auth/oauth/clients/${clientId}/issue-token`,
                data: {
                    ...props,
                    account_level: 'student',
                },
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_AUTHORIZING_CLIENT', error)
        }
    }

    public async getClientInfo({
        clientId,
    }: {
        clientId: string
    }): Promise<ApiResponse<{ logo: string; name: string }>> {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'GET',
                url: `auth/oauth/clients/${clientId}`,
            })

            return resp?.data
        } catch (error) {
            throw handleError('ERR_GETTIN_CLIENT_INFO', error)
        }
    }
}

const wsSdk = new WsSDK({ apiUrl: API_URL, wsClientId: WESTUDENTS_CLIENT_ID })

// Object.freeze(wsSdk)

export default wsSdk
