import Container from 'components/container'
import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    box-sizing: border-box;
`

const StyledContainer = styled(Container)`
    > div {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`

interface ICardLayout {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    disableFooter?: boolean
    maxWidth?: 'lg' | 'md' | 'sm'
}

const MainLayout = ({ children, maxWidth = 'lg' }: ICardLayout) => {
    return (
        <StyledContainer maxWidth={maxWidth}>
            <div>
                <Card>{children}</Card>
                <p>
                    Serve aiuto? <a>Contattaci</a>
                </p>
            </div>
        </StyledContainer>
    )
}

export default MainLayout
