import { useTheme } from '@emotion/react'
import Button from 'components/button'
import { useConfigParams } from 'hooks'
import * as React from 'react'
import styled from 'styled-components'
import wsSdk from 'utils/ws-sdk'
import useAuth from 'hooks/use-auth'
import CardLayout from 'components/card-layout'
import { CircularProgress } from '@mui/material'

const RoundLogo = styled.div`
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    z-index: 10;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.22);
    img {
        width: 100%;
    }
`

const Scope = styled.div`
    padding: 0 2rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    border-top: ${({ theme }) => `1px solid ${theme.palette.text.secondary}`};
`

const ConfirmStep = () => {
    const { handleLogout, user } = useAuth()
    const [clientInfo, setClientInfo] = React.useState<{ logo: string; name: string }>()
    const theme = useTheme()
    const [loading, setLoading] = React.useState(false)
    const { redirectUri, clientId, scope, state } = useConfigParams()
    const scopes = scope ? scope.split(' ') : []

    const onCancel = () => {
        handleLogout()
    }

    const getClientInfo = async () => {
        if (clientId) {
            await wsSdk.getClientInfo({ clientId }).then((resp) => {
                setClientInfo(resp.data)
            })
        }
    }

    const onConfirm = () => {
        if (clientId && scope && redirectUri) {
            setLoading(true)
            wsSdk
                .authorizeClient({ clientId, scope, state: state || undefined, redirect_uri: redirectUri })
                .then((res) => {
                    setLoading(false)
                    if (window.opener && window.opener !== window) {
                        window.opener.location = res.data
                        window.close()
                    } else {
                        window.location.href = res.data
                    }
                })
                .catch((res) => {
                    setLoading(false)
                })
        }
    }

    React.useEffect(() => {
        getClientInfo()
    }, [])

    if (clientInfo) {
        return (
            <div style={{ padding: '2.5rem' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <RoundLogo>
                        <img src={user?.avatar} alt="WeStudents" />
                    </RoundLogo>
                    {clientInfo?.logo ? (
                        <RoundLogo style={{ marginLeft: '-1.5rem', zIndex: '100' }}>
                            <img src={clientInfo?.logo} alt="WeStudents" />
                        </RoundLogo>
                    ) : null}
                </div>
                <h3 style={{ textAlign: 'center' }}>{`${user?.first_name} ${user?.last_name}`}</h3>
                {clientInfo?.name ? (
                    <h4 style={{ textAlign: 'center' }}>{`${clientInfo?.name} sta richiedendo l’accesso a:`}</h4>
                ) : (
                    <h4 style={{ textAlign: 'center' }}>{`Consenti l’accesso a:`}</h4>
                )}
                {scope
                    ? scopes.map((item, index) => (
                          <Scope
                              style={{
                                  borderBottom:
                                      index === scopes.length - 1
                                          ? `1px solid ${(theme as any).palette.text.secondary}`
                                          : '',
                              }}>
                              <p>{item}</p>
                          </Scope>
                      ))
                    : null}
                <p style={{ fontSize: '0.8rem', color: (theme as any).palette.text.secondary, textAlign: 'center' }}>
                    `Cliccando su consenti, dai il consenso a usare le tue informazioni personali in accordo con le loro
                    rispettive`
                    <a href="https://westudents.it/privacy-policy-app-intro" rel="noopener noreferrer" target="_blank">
                        Privacy Policies
                    </a>{' '}
                    e{' '}
                    <a href="https://westudents.it/termini-e-condizioni" rel="noopener noreferrer" target="_blank">
                        Termini e Servizi
                    </a>{' '}
                    .
                </p>
                <div style={{ display: 'flex', columnGap: '1rem' }}>
                    <Button size="large" fullWidth variant="outlined" onClick={() => onCancel()}>
                        Annulla
                    </Button>
                    <Button loading={loading} size="large" fullWidth variant="contained" onClick={() => onConfirm()}>
                        Consenti
                    </Button>
                </div>
            </div>
        )
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '15rem' }}>
            <CircularProgress color="primary" />
        </div>
    )
}

export default ConfirmStep
