import styled from 'styled-components'
import Logo from 'assets/svgs/logo-demo.svg'
import WsLogo from 'assets/svgs/logo-white.svg'
import MainLayout from 'components/main-layout'
import Button from 'components/button'
import { useSearchParams } from 'react-router-dom'
import Input from 'components/input'

const demoLoginConfigs = {
    redirectUri: 'https://login.westudents.it/success',
    clientId: '62a346862c9d2032724cb1aa',
    scope: 'profile_data',
}

const Title = styled.h2`
    color: ${({ theme }) => theme.palette.primary.main};
`

const Divider = styled.div`
    background: grey;
    height: 1px;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    padding-bottom: 2rem;
`

const DemoLogin = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    if (token) {
        return (
            <Wrapper>
                <img style={{ width: '8rem' }} src={Logo} alt="LogoIpsum" />
                <Title>Accesso effettuato con successo</Title>
            </Wrapper>
        )
    }

    return (
        <MainLayout maxWidth="sm">
            <Wrapper>
                <img style={{ width: '50%', margin: '3rem auto' }} src={Logo} alt="WeStudents" />
                <h4 style={{ margin: 0, textAlign: 'center' }}>Per accedere alla piattaforma Lorem Ipsum</h4>
                <Button
                    onClick={() =>
                        window.open(
                            `https://login.westudents.it?clientId=${demoLoginConfigs.clientId}&scope=${demoLoginConfigs.scope}&redirectUri=${demoLoginConfigs.redirectUri}`,
                            'popUpWindow',
                            'height=500,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
                        )
                    }
                    style={{ borderRadius: '5rem' }}
                    startIcon={<img style={{ width: '2rem' }} src={WsLogo} alt="We" />}
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained">
                    Continua con WeStudents
                </Button>
                <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                    <Divider />
                    <h5 style={{ margin: '0 1rem' }}>oppure</h5>
                    <Divider />
                </div>
                <Input fullWidth color="secondary" label="Indirizzo mail" />
                <Input fullWidth color="secondary" label="Password" />
                <h4 style={{ margin: '0.5rem 0' }}>Hai dimenticato la password?</h4>
                <Button style={{ borderRadius: '5rem' }} color="secondary" fullWidth size="large" variant="contained">
                    Accedi
                </Button>
                <Divider />
                <h3 style={{ margin: 0, textAlign: 'center' }}>Non hai un account?</h3>
                <Button style={{ borderRadius: '5rem' }} color="secondary" fullWidth size="large" variant="outlined">
                    Registrati
                </Button>
            </Wrapper>
        </MainLayout>
    )
}

export default DemoLogin
