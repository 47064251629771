import * as React from 'react'
import { TextField, StandardTextFieldProps } from '@mui/material'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
const StyledTextField = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme, color }) => theme.palette[`${color}`].main};
    }
`

interface InputProps extends StandardTextFieldProps {
    fieldType?: 'mail-code' | 'phone-code'
}

const Input = (props: InputProps) => {
    const { color = 'primary' } = props
    if (props.fieldType === 'mail-code') {
        return (
            <InputMask mask="9-9-9-9-9" value={props.value as string} onChange={props.onChange} maskPlaceholder={null}>
                <StyledTextField placeholder="X-X-X-X-X" error={!!props.helperText} color={color} {...props} />
            </InputMask>
        )
    }
    if (props.fieldType === 'phone-code') {
        return (
            <InputMask
                mask="9-9-9-9-9-9"
                value={props.value as string}
                onChange={props.onChange}
                maskPlaceholder={null}>
                <StyledTextField placeholder="X-X-X-X-X-X" error={!!props.helperText} color={color} {...props} />
            </InputMask>
        )
    }
    return <StyledTextField error={!!props.helperText} color={color} {...props} />
}

export default Input
