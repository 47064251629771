import * as React from 'react'
import LoginPage from 'pages/login-page'
import DemoPage from 'pages/demo'
import ThemeProvider from 'providers/theme'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import theme from 'providers/theme/defaultTheme'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'
import useAuth from 'hooks/use-auth'
import SuccessPage from 'pages/success-page'

const GlobalStyles = styled.div`
    h1 {
        font-weight: 700;
        font-size: 3rem;
        margin: 0;
    }

    h2 {
        font-weight: 700;
        margin: 0;
    }
    a {
        color: ${({ theme }) => theme.palette.primary.main};
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

function App() {
    const { restoreAuth } = useAuth()
    React.useEffect(() => {
        restoreAuth()
    }, [])
    return (
        <div className="App">
            <ThemeProvider>
                <MuiThemeProvider theme={theme}>
                    <GlobalStyles>
                        <Routes>
                            <Route path="/" element={<LoginPage />} />
                            <Route path="/demo" element={<DemoPage />} />
                            <Route path="/success" element={<SuccessPage />} />
                        </Routes>
                    </GlobalStyles>
                </MuiThemeProvider>
            </ThemeProvider>
        </div>
    )
}

export default App
