import { ConfirmationResult } from 'firebase/auth'

export interface IAuth {
    user?: User
    isLoading: boolean
    isLogged: boolean
    phoneConfirm?: ConfirmationResult
    error: string
    restoreLoading: boolean
    qrCode?: {
        key: string
        qr_code: string
        _id: string
    }
}

export enum AuthActions {
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT = 'LOGOOT',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    RESTORE_AUTH = 'RESTORE_AUTH',
    RESTORE_AUTH_SUCCESS = 'RESTORE_AUTH_SUCCESS',
    RESTORE_AUTH_ERROR = 'RESTORE_AUTH_ERROR',
    GET_QR_CODE = 'GET_QR_CODE',
    GET_QR_CODE_SUCCESS = 'GET_QR_CODE_SUCCESS',
    GET_QR_CODE_ERROR = 'GET_QR_CODE_ERROR',
    VERIFY_QR_CODE = 'VERIFY_QR_CODE',
    VERIFY_QR_CODE_SUCCESS = 'VERIFY_QR_CODE_SUCCESS',
    VERIFY_QR_CODE_ERROR = 'VERIFY_QR_CODE_ERROR',
    REQUEST_GRANT = 'REQUEST_GRANT',
    REQUEST_GRANT_SUCCESS = 'REQUEST_GRANT_SUCCESS',
    REQUEST_GRANT_ERROR = 'REQUEST_GRANT_ERROR',
    CLEAR_ERROR = 'CLEAR_ERROR',
}

export interface ActionWithPayload {
    type: string
    payload: any
}
