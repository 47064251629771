import styled from 'styled-components'
import ErrorIcon from 'assets/Error-icon.png'

interface IErrorComponent {
    text: string
}

const Wrapper = styled.div`
    p {
        color: rgb(171, 171, 171);
        margin: 0.5rem 0;
    }
    text-align: center;
    box-sizing: border-box;
    > h2 {
        color: ${({ theme }) => theme.palette.error.light};
        margin: 0;
    }
    a {
        color: ${({ theme }) => theme.palette.primary.main};
    }
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ErrorComponent = ({ text }: IErrorComponent) => {
    return (
        <Wrapper>
            {/* <img style={{ width: '4rem' }} src={ErrorIcon} alt="WeStudents" /> */}
            <h2>{text}</h2>
            <p>
                Se sei uno studente prova a ricaricare la pagina. Se sei uno sviluppatore clicca{' '}
                <a href="https://ws-developers-docs.netlify.app/api-reference/#/" rel="noopener noreferrer">
                    qui.
                </a>
            </p>
        </Wrapper>
    )
}

export default ErrorComponent
