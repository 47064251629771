import { createTheme, Theme as MuiTheme } from '@mui/material/styles'

const theme = createTheme({
    // @ts-ignore
    shadows: ['none'],
    palette: {
        primary: {
            main: '#FF9D64',
            light: '#FFC29C',
            contrastText: '#fff',
        },
        secondary: {
            main: '#1976d2',
        },
        error: {
            main: '#C32328',
            light: '#E86F73',
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#414042',
            secondary: '#ABABAB',
        },
        divider: '#FF9D64',
    },
})

export default theme

export type Theme = MuiTheme
