import { useSearchParams } from 'react-router-dom'

const useConfigParams = () => {
    const [searchParams] = useSearchParams()
    const redirectUri = searchParams.get('redirectUri')
    const clientId = searchParams.get('clientId')
    const scope = searchParams.get('scope')
    const state = searchParams.get('state')
    return {
        redirectUri,
        clientId,
        scope,
        state,
    }
}

export default useConfigParams
