import { Container as MuiContainer, ContainerProps } from '@mui/material'

const Container = (props: ContainerProps) => {
    return (
        <MuiContainer maxWidth={props.maxWidth ? props.maxWidth : 'xl'} {...props}>
            {props.children}
        </MuiContainer>
    )
}

export default Container
