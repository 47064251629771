import { getAuth, RecaptchaVerifier } from 'firebase/auth'
const auth = getAuth()

export const addPhonePrefix = (phone: string): string => {
    if (phone.startsWith('+')) return phone
    if (phone.startsWith('0039')) return phone.replace('0039', '+39')
    if (phone.startsWith('39') && phone.length > 10) return `+${phone}`

    return `+39${phone}`
}

export const generateRecaptcha = () => {
    ;(window as any).recaptchaVerifier = new RecaptchaVerifier(
        'sign-in-button',
        {
            size: 'invisible',
            callback: () => {},
        },
        auth,
    )
}
