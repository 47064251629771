export function handleError(key: string, error: any): WsSdkError {
    console.log(error.message)
    if (error.response) {
        return new WsSdkError({ message: key, data: error.response.data })
    }

    if (error.data) {
        return new WsSdkError({ message: key, data: error.data })
    }

    return new WsSdkError({ message: key, data: error })
}

interface WsSdkErrorData {
    code: string
    data: unknown
}

class WsSdkError extends Error {
    date: Date
    data: WsSdkErrorData

    constructor({ message = 'WS_SDK_ERROR', data }: { message: string; data: WsSdkErrorData }) {
        super(message)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, WsSdkError)
        }

        this.data = data
        this.date = new Date()
    }
}

export default WsSdkError
