import styled from 'styled-components'
import Logo from 'assets/svgs/logo.svg'

const Title = styled.h2`
    color: ${({ theme }) => theme.palette.primary.main};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SuccessMessage = () => {
    return (
        <Wrapper>
            <img style={{ width: '8rem' }} src={Logo} alt="WeStudents" />
            <Title>Grazie per aver effettuato l'accesso con WeStudents!</Title>
            <p>Verrai re-indirizzato alla pagina d’origine</p>
        </Wrapper>
    )
}

export default SuccessMessage
