import { useGlobalStore } from 'store'

import bindActions from 'store/bindActions'
import loginReducer from 'store/auth'
import { IAuth } from 'store/auth/types'

const { actions } = loginReducer

/**
 * useLogin Custom Hook
 */
const useLogin: any = () => {
    const { state, dispatch } = useGlobalStore()

    // List of Props
    const { auth } = state

    // List of Actions
    const { handleLogin, handleLogout, restoreAuth, handleGetQr, checkQr, clearError, handleRequestGrant } = actions

    // Bind Actions
    const loginActions = bindActions(
        {
            handleLogin,
            handleLogout,
            restoreAuth,
            handleRequestGrant,
            handleGetQr,
            checkQr,
            clearError,
        },
        dispatch,
    )

    return { ...(auth as IAuth), ...loginActions }
}

export default useLogin
