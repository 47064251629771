import { IAuth, AuthActions, ActionWithPayload } from './types'

export const initialState: IAuth = {
    user: undefined,
    isLoading: false,
    isLogged: false,
    phoneConfirm: undefined,
    restoreLoading: false,
    error: '',
    qrCode: undefined,
}

export default function login(state = initialState, action: ActionWithPayload) {
    switch (action.type) {
        case AuthActions.REQUEST_GRANT:
            return {
                ...state,
                error: '',
                isLoading: true,
            }
        case AuthActions.REQUEST_GRANT_SUCCESS:
            return {
                ...state,
                phoneConfirm: action.payload,
                isLoading: false,
            }
        case AuthActions.REQUEST_GRANT_ERROR:
            return {
                ...state,
                isLoading: false,
                phoneConfirm: undefined,
                error: action.payload,
            }
        case AuthActions.LOGIN:
            return {
                ...state,
                error: '',
                isLoading: true,
            }
        case AuthActions.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.account,
                isLoading: false,
                isLogged: true,
            }
        case AuthActions.LOGIN_ERROR:
            return {
                ...state,
                isLoading: false,
                isLogged: false,
                error: action.payload,
            }
        case AuthActions.VERIFY_QR_CODE:
            return {
                ...state,
                error: '',
            }
        case AuthActions.VERIFY_QR_CODE_SUCCESS:
            return {
                ...state,
                user: action.payload.account,
                isLogged: true,
            }
        case AuthActions.VERIFY_QR_CODE_ERROR:
            return {
                ...state,
                isLogged: false,
            }
        case AuthActions.GET_QR_CODE:
            return {
                ...state,
                error: '',
            }
        case AuthActions.GET_QR_CODE_SUCCESS:
            return {
                ...state,
                qrCode: action.payload,
            }
        case AuthActions.GET_QR_CODE_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case AuthActions.RESTORE_AUTH:
            return {
                ...state,
                isLogged: false,
                user: undefined,
                error: '',
                restoreLoading: true,
            }
        case AuthActions.RESTORE_AUTH_SUCCESS:
            return {
                ...state,
                user: action.payload.account,
                restoreLoading: false,
                isLogged: true,
            }
        case AuthActions.RESTORE_AUTH_ERROR:
            return {
                ...state,
                restoreLoading: false,
                isLogged: false,
                error: action.payload,
            }
        case AuthActions.LOGOUT_SUCCESS:
            return {
                ...state,
                user: undefined,
                isLogged: false,
            }
        case AuthActions.CLEAR_ERROR:
            return {
                ...state,
                error: '',
            }
        default: {
            return state
        }
    }
}
