import loginReducer from 'store/auth'

import { ActionWithPayload, IAuth } from 'store/auth/types'

import { logger } from 'store/middlewares'

interface IState {
    auth: IAuth
}

export const initialState: IState = {
    auth: loginReducer.initialState,
}

export default function mainReducer(state: IState, action: ActionWithPayload) {
    // Receiving previous state here
    const { auth } = state

    // Receiving current state here
    const currentState = {
        auth: loginReducer.reducer(auth, action),
    }

    // Middlewares
    logger(action, state, currentState)

    return currentState
}
