import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

import defaultTheme from './defaultTheme'
import { merge } from 'lodash'

const ThemeProvider = ({
    children,
    theme,
}: {
    children: string | JSX.Element | JSX.Element[]
    theme?: Theme
}): JSX.Element => {
    const mergedTheme = merge(theme, defaultTheme)
    return <StyledComponentsThemeProvider theme={mergedTheme}>{children}</StyledComponentsThemeProvider>
}

export default ThemeProvider
