import wsSdk from 'utils/ws-sdk'
import * as Storage from 'utils/local-storage'
import WsSdkError from 'utils/ws-sdk/utils/ws-sdk-error'
import { AuthActions } from './types'
import { addPhonePrefix, generateRecaptcha } from 'utils/phone'
import store, { useGlobalStore } from 'store'
import { ConfirmationResult } from 'firebase/auth'

export function handleLogin({
    loginType,
    grant: code,
    phoneConfirm,
}: {
    loginType: 'email' | 'phone'
    grant: string
    phoneConfirm?: ConfirmationResult
}) {
    return async function (dispatch: any) {
        dispatch({
            type: AuthActions.LOGIN,
        })
        try {
            let grant
            grant = code
            if (loginType === 'phone') {
                if (phoneConfirm) {
                    const confirmCodeResp = await (phoneConfirm as ConfirmationResult).confirm(code).then((res) => {
                        return res?.user
                    })

                    if (confirmCodeResp) {
                        const userIdToken = await confirmCodeResp.getIdToken()
                        if (userIdToken) {
                            grant = userIdToken
                        }
                    }
                }
            }
            const resp = await wsSdk.getToken({ loginType, grant })
            wsSdk.setAuthData(resp.data)
            Storage.set('auth', JSON.stringify(resp.data))
            dispatch({
                type: AuthActions.LOGIN_SUCCESS,
                payload: resp.data,
            })
        } catch (error) {
            dispatch({
                type: AuthActions.LOGIN_ERROR,
                payload: (error as WsSdkError).data,
            })
        }
    }
}

export function handleLogout() {
    return async function (dispatch: any) {
        try {
            await Storage.del('auth')
            dispatch({
                type: AuthActions.LOGOUT_SUCCESS,
            })
        } catch (error) {}
    }
}

export function clearError() {
    return async function (dispatch: any) {
        try {
            dispatch({
                type: AuthActions.CLEAR_ERROR,
            })
        } catch (error) {}
    }
}

export function restoreAuth() {
    return async function (dispatch: any) {
        dispatch({
            type: AuthActions.RESTORE_AUTH,
        })
        try {
            const resp = await Storage.get('auth')
            const authData = resp !== null ? JSON.parse(resp as unknown as string) : undefined
            if (authData) {
                wsSdk.setAuthData(authData as WsAuthData)
                dispatch({
                    type: AuthActions.RESTORE_AUTH_SUCCESS,
                    payload: authData,
                })
            } else {
                dispatch({
                    type: AuthActions.RESTORE_AUTH_ERROR,
                })
            }
        } catch (error) {
            dispatch({
                type: AuthActions.RESTORE_AUTH_ERROR,
                payload: (error as WsSdkError).data,
            })
        }
    }
}

export function handleGetQr() {
    return async function (dispatch: any) {
        dispatch({
            type: AuthActions.GET_QR_CODE,
        })
        try {
            const resp = await wsSdk.createQrCode()
            dispatch({
                type: AuthActions.GET_QR_CODE_SUCCESS,
                payload: resp.data,
            })
        } catch (error) {
            dispatch({
                type: AuthActions.GET_QR_CODE_ERROR,
                payload: (error as WsSdkError).data,
            })
        }
    }
}

export function checkQr({ key, id }: { key: string; id: string }) {
    return async function (dispatch: any) {
        dispatch({
            type: AuthActions.VERIFY_QR_CODE,
        })
        try {
            const resp = await wsSdk.checkQr({ key, qrCode: id })
            wsSdk.setAuthData(resp.data)
            Storage.set('auth', JSON.stringify(resp.data))
            dispatch({
                type: AuthActions.VERIFY_QR_CODE_SUCCESS,
                payload: resp.data,
            })
        } catch (error) {
            dispatch({
                type: AuthActions.VERIFY_QR_CODE_ERROR,
                payload: (error as WsSdkError).data,
            })
        }
    }
}

export function handleRequestGrant({
    type,
    user,
    resend = false,
}: {
    type: 'phone' | 'mail'
    user: string
    resend: boolean
}) {
    return async function (dispatch: any) {
        dispatch({
            type: AuthActions.REQUEST_GRANT,
        })
        try {
            let payload
            if (type === 'mail') {
                await wsSdk.requestGrant({ email: user })
            }
            if (type === 'phone') {
                if (!resend) {
                    generateRecaptcha()
                }
                const appVerifier = (window as any).recaptchaVerifier
                const confirm = await wsSdk.requestPhoneGrant(addPhonePrefix(user), appVerifier)
                payload = confirm
            }
            dispatch({
                type: AuthActions.REQUEST_GRANT_SUCCESS,
                payload,
            })
        } catch (error) {
            dispatch({
                type: AuthActions.REQUEST_GRANT_ERROR,
                payload: (error as WsSdkError).data,
            })
        }
    }
}
