import styled from 'styled-components'
import * as React from 'react'
import { CircularProgress } from '@mui/material'
import Logo from 'assets/svgs/logo.svg'
import useAuth from 'hooks/use-auth'

const OrangeWrapper = styled.div`
    background: ${({ theme }) => theme.palette.primary.main};
    width: 10rem;
    height: 10rem;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        width: 100%;
    }
`

const Head = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.5rem;
    > img {
        width: 4rem;
    }
`

const Wrapper = styled.div`
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: center;
`

const QrCodeSection = React.memo(() => {
    const { qrCode, handleGetQr, checkQr } = useAuth()
    const intervalRef = React.useRef<null | NodeJS.Timeout>(null)

    const handleCheckQr = React.useCallback((qrCode, secretKey) => {
        checkQr({ id: qrCode, key: secretKey })
    }, [])

    React.useEffect(() => {
        handleGetQr()
    }, [])

    React.useEffect(() => {
        if (qrCode) {
            intervalRef.current = setInterval(() => handleCheckQr(qrCode._id, qrCode.key), 2000)
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef?.current)
            }
        }
    }, [qrCode, handleCheckQr])

    return (
        <Wrapper>
            <Head>
                <img src={Logo} alt="WeStudents" />
            </Head>
            <OrangeWrapper>
                {qrCode ? <img src={qrCode.qr_code} alt="" /> : <CircularProgress color="info" />}
            </OrangeWrapper>
            <p style={{ padding: '0 3rem' }}>
                Scannerizza il QR-Code con l’app WeStudents per effettuare un accesso rapido.
            </p>
        </Wrapper>
    )
})

export default QrCodeSection
