import axios, { AxiosInstance, AxiosResponse, Method } from 'axios'
import * as Storage from 'utils/local-storage'
import { isAfter, parseDate } from 'utils/date'
import WsSdkError from './utils/ws-sdk-error'

interface ApiCallProps {
    method?: Method
    // eslint-disable-next-line @typescript-eslint/ban-types
    data?: object | string
    url: string
    baseURL?: string
    headers?: { [key: string]: string }
    params?: { [key: string]: string }
    onUploadProgress?: (progressEvent: number) => void
    onDownloadProgress?: (progressEvent: number) => void
}

export default class Api {
    private static _instance: Api | null = null
    private api!: AxiosInstance
    private authData?: WsAuthData

    public static getInstance(): Api {
        if (!(Api._instance instanceof Api)) {
            throw new Error('Api not initialized yet')
        }

        return Api._instance
    }

    public static destroyInstance(): void {
        Api._instance?.destroy()
        Api._instance = null
    }

    constructor({ apiUrl, wsClientId }: { apiUrl: string; wsClientId: string }) {
        if (Api._instance) {
            return Api._instance
        }

        Api._instance = this
        Api._instance.api = axios.create({
            baseURL: apiUrl,
            headers: {
                'ws-client-id': wsClientId,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        })

        Api._instance.api.interceptors.response.use(
            (response) => response,
            async (err: { response: AxiosResponse<any> }): Promise<unknown> => {
                if (err.response !== undefined && err.response.status === 401) {
                    if (isAfter(new Date(), parseDate(Api._instance?.authData?.refresh_token_expires_at as string))) {
                        Storage.del('auth')
                        Api._instance?.deleteAuthData()
                        throw new WsSdkError({
                            message: 'Refresh token expired',
                            data: {
                                code: 'REFRESH_TOKEN_EXPIRED',
                                data: new Error('Refresh token expired'),
                            },
                        })
                    }

                    try {
                        const refreshResp = (await Api._instance?._call({
                            method: 'POST',
                            url: 'auth/oauth/token',
                            data: {
                                grant_type: 'refresh_token',
                                grant: Api._instance.authData?.refresh_token,
                            },
                        })) as unknown as AxiosResponse<GetTokenResponse>

                        await Storage.set(
                            'auth',
                            JSON.stringify({
                                ...Api._instance?.authData,
                                ...refreshResp.data.data,
                                account: Api._instance?.authData?.account || '',
                            }),
                        )
                        Api._instance?.setAuthData({
                            ...Api._instance?.authData,
                            ...refreshResp.data.data,
                            account: Api._instance?.authData?.account,
                        })
                    } catch (error) {
                        Storage.del('auth')
                        Api._instance?.deleteAuthData()
                        throw new WsSdkError({
                            message: 'Refresh token expired',
                            data: {
                                code: 'REFRESH_TOKEN_EXPIRED',
                                data: new Error('Refresh token expired'),
                            },
                        })
                    }
                }

                throw err
            },
        )

        return Api._instance
    }

    public setAuthData(authData: WsAuthData): void {
        if (Api._instance) {
            Api._instance.authData = authData
            Api._instance.api.defaults.headers.common['Authorization'] = authData.access_token
        } else {
            throw new Error('Api not initialized')
        }
    }

    public deleteAuthData(): void {
        if (Api._instance) {
            Api._instance.authData = undefined
            Api._instance.api.defaults.headers.common['Authorization'] = ``
        } else {
            throw new Error('Api not initialized')
        }
    }

    public setAppVersion(appVersion: string): void {
        if (Api._instance) {
            Api._instance.api.defaults.headers.common['Authorization'] = ''
        } else {
            throw new Error('Api not initialized')
        }
    }

    private _call({
        method = 'get',
        data,
        url,
        baseURL,
        headers,
        params,
        onUploadProgress,
        onDownloadProgress,
    }: ApiCallProps): Promise<AxiosResponse> | undefined {
        return Api._instance?.api.request({
            method: method || 'get',
            data,
            url,
            baseURL,
            headers,
            params,
            onUploadProgress,
            onDownloadProgress,
        })
    }

    public async call(props: ApiCallProps): Promise<AxiosResponse> {
        const resp = await Api._instance?._call(props)
        if (resp !== undefined) {
            return resp
        } else {
            throw new Error('Api error')
        }
    }

    public destroy(): void {
        this.destroy()
    }
}
