import { parse } from 'date-fns/esm'
import {
    format,
    parseISO,
    isAfter as fnsIsAfter,
    addSeconds as fnsAddSeconds,
    addHours as fnsAddHours,
    addMinutes as fnsAddMinutes,
    intervalToDuration as fnsIntervalToDuration,
    differenceInSeconds as fnsDifferenceInSeconds,
    formatDistance as fnsFormatDistance,
} from 'date-fns'

import { it } from 'date-fns/locale'

const DEFAULT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"

export const parseDate = (dateString: string, formatString?: string, isISO = true, referenceDate?: Date): Date => {
    return isISO
        ? parseISO(dateString)
        : parse(dateString, formatString || DEFAULT_FORMAT, referenceDate || new Date(), {
              locale: it,
          })
}

export const parseTime = (dateString: string, formatString?: string, referenceDate?: Date): number => {
    return parse(dateString, formatString || DEFAULT_FORMAT, referenceDate || new Date(), {
        locale: it,
    }).getTime()
}

export const formatDate = (date: Date, formatString?: string): string => {
    return format(date, formatString || DEFAULT_FORMAT, { locale: it })
}

export const isAfter = (d1: Date, d2: Date): boolean => {
    return fnsIsAfter(d1, d2)
}

export const addSeconds = (date: Date, amount: number): Date => {
    return fnsAddSeconds(date, amount)
}

export const addHours = (date: Date, amount: number): Date => {
    return fnsAddHours(date, amount)
}

export const addMinutes = (date: Date, amount: number): Date => {
    return fnsAddMinutes(date, amount)
}

export const intervalToDuration = (
    startDate: Date,
    endDate: Date,
): { years?: number; months?: number; days?: number; hours?: number; minutes?: number; seconds?: number } => {
    return fnsIntervalToDuration({
        start: startDate,
        end: endDate,
    })
}

export const differenceInSeconds = (d1: Date, d2: Date): number => {
    return fnsDifferenceInSeconds(d1, d2)
}

export const formatDistance = (d1: Date, d2: Date): string => {
    return fnsFormatDistance(d1, d2, { locale: it })
}
