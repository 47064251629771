import localforage from 'localforage'

const INDEXED_DB_NAME = process.env.REACT_APP_INDEXED_DB_NAME || ''
const INDEXED_DB_STORE_NAME = process.env.REACT_APP_INDEXED_DB_STORE_NAME || ''
const INDEXED_DB_VERSION = process.env.REACT_APP_INDEXED_DB_VERSION || ''
const INDEXED_DB_STORE_DESCRIPTION = process.env.REACT_APP_INDEXED_DB_STORE_DESCRIPTION || ''

localforage.config({
    driver: localforage.INDEXEDDB,
    name: INDEXED_DB_NAME,
    version: parseFloat(INDEXED_DB_VERSION),
    storeName: INDEXED_DB_STORE_NAME,
    description: INDEXED_DB_STORE_DESCRIPTION,
})

export const get = (key: string, callback?: () => void) => {
    return localforage.getItem(key, callback)
}

export const set = (key: string, data: string, callback?: () => void) => {
    return localforage.setItem(key, data, callback)
}

export const del = (key: string, callback?: () => void) => {
    return localforage.removeItem(key, callback)
}
